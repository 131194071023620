import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)
import config from '/package.json'

export default new Router({
  routes: [
    {
      path: '/',
      redirect: '/e'
    },
    {
      path: '/e/:id',
      component: resolve => require(['@/views/evaluate'], resolve),
      meta:{ title: '客户评价-' + config.version }
    },
    {
      path: '/b',
      component: resolve => require(['@/views/board'], resolve),
      meta:{ title: '概要看板' }
    },
    {
      path: '/q/:id/:type?/:token?',
      component: resolve => require(['@/views/research/Research'], resolve),
      meta:{ title: '客户评价' }
    },
    {
      path: '/404',
      name:'404',
      component: resolve => require(['@/views/NotFound'], resolve),
      meta:{ title: '404 NotFound' }
    }
  ]
})
